<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Modes de paiement</v-card-title>
        <v-card-subtitle>Consultez vos modes de paiement</v-card-subtitle>
        <v-card-text>
            <v-list>
                <v-list-item link v-for="(item, index) in payment" :key="index">
                    <div class="d-flex" style="width:100%;align-items: center">
                        <span style="width:20%">{{ item.type }}</span>
                        <span style="width:20%">{{ item.user_name }}</span>
                        <span style="width:30%">{{ item.number_card }}</span>
                        <span style="width:20%">{{ item.expiration_date }}</span>
                        <v-switch v-model="item.enable" switch></v-switch>
                        <v-btn icon class="ml-2">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </div>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Payments',
    data() {
        return {
            payment : JSON.parse(localStorage.getItem('settings')).main.payment,
            payment_backup : JSON.parse(localStorage.getItem('settings')).main.payment,
        }
    },
    methods : {
        saveSettings() {
            if (this.payment_backup !== this.payment) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.main.payment = this.payment;
                this.payment_backup = JSON.parse(JSON.stringify(this.payment));
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.payment = JSON.parse(JSON.stringify(this.payment_backup));
        },
        isChanges () {
            if (JSON.stringify(this.payment_backup) !== JSON.stringify(this.payment)) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>